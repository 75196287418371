<template>
    <section id="balance">
        <!--BALANCE DEFAULT-->
        <div class="balance" v-if="show">
                <div class="balance__title">
                    {{ $t("dashboardBalance.title") }}
                </div>
            <div class="balance__body">
                <div class="balance__sum">
                    <div class="balance__sum-amount">
                        <svg-vue icon="dai" />
                        <span class="balance__sum-amount-num">{{ total ? total : 0 }}</span> DAI
                        <!-- <button 
                            class="balance__btn" 
                            :class="{ 'balance__btn--active': !withdrawSuccess }"
                        >
                            {{ 
                                withdrawSuccess ? 
                                $t('dashboardBalanceModal.successBtnWithdraw') :
                                $t('dashboardBalanceModal.withdrawPending')
                            }}
                        </button> -->
                    </div>
                    <button
                        v-if="resultAccountTree && !activeWithdraw && !withdrawSuccess"
                        type="button"
                        class="btn btn-active"
                        @click="withdrawMoney()"
                        >
                        {{ $t("dashboardBalance.withdraw") }}
                    </button>
                </div>
                <div class="balance__address" :class="refundData ? 'changed' : ''">
                    {{ !refundData ? $t("dashboardBalance.walletAccount") : $t("dashboardBalance.changeAddress") }}
                    <span>{{ refundData ? refundData.wallet : walletShort }}</span>
                    {{ refundData ? refundData.email : "" }}
                </div>
                <p v-if="!withdrawSuccess" @click="showModal = true" class="balance__call-modal">
                    <span>{{ $t("dashboardBalance.hackedWallet") }}</span> <svg-vue icon="write" />
                </p>
                <div class="balance__success" v-if="withdrawSuccess">
                    <svg-vue icon="success" />
                    {{ $t("dashboardBalanceModal.successWithdraw") }}
                </div>
            </div>
        </div>

        <!-- MODAL -->
        <div class="modal modal-sm" v-if="showModalWithdraw">
            <button type="button" class="close" @click="showModalWithdraw = false"><svg-vue icon="close" /></button>
            <div class="modal__body">
                {{ $t("dashboardBalance.withdrawModer") }}
            </div>
            <button type="button" class="btn btn-default" @click="showModalWithdraw = false">
                {{ $t("dashboardBalance.successBtn") }}
            </button>
        </div>
        <HackedModal :showModal="showModal" @closeModal="closeModal" @resultRequest="resultHackedModal" />
    </section>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from "axios"
import HackedModal from "./HackedWalletModal.vue"
import web3 from "web3"
import { sliceString } from '@/helpers/sliceString'
export default {
    components: {
        HackedModal,
    },
    data() {
        return {
            withdrawSuccess: false,
            activeWithdraw: true,
            resultAccountTree: {},
            refundData: false,
            showModal: false,
            showModalWithdraw: false,
            show: false,
            total: null,
            message: "Money mistakenly frozen in the first days of launching the system",
            account: '',
        }
    },
    mounted() {
        if (this.getAccount) {
            this.account = this.getAccount
        }
        this.render()
        window.addEventListener('click', (event) => {
            if (!this.$el.contains(event.target)) {
                this.showModal = false
            }
        })
    },
    computed: {
        ...mapGetters('crypto', ['getAccount']),
        walletShort() {
            if (this.getAccount) {
                return sliceString(this.getAccount) 
            }
            return 'default'
        },
        _getAccount() {
            return web3.utils.toChecksumAddress(this.getAccount)
        },
    },
    methods: {
        resultHackedModal() {
            this.render()
        },
        async withdrawMoney() {
            const account = this._getAccount
            await this.$store.dispatch("crypto/withdrawMoney", {
                index: this.resultAccountTree.index,
                address: account,
                amount: this.resultAccountTree.amount,
                merkleProof: this.resultAccountTree.proof,
                sum: this.total,
            })
        },
        async render() {
            let account
            if (this._getAccount) {
                account = this.getAccount
            }
            const url = `https://web3up.net/api/hung-money/${account}`
            const urlPayment = `https://web3up.net/api/get-payment-tree/${account}`
            try {
                await axios.get(url).then((res) => {
                    if (res.data.status === 200) {
                        this.show = true
                        this.total = res.data.data.total
                        this.withdrawSuccess = res.data.withdraw
                        if (res.data.data.refund_status) {
                            this.refundData = {
                                email: res.data.data.refund_data.email,
                                wallet: res.data.data.refund_data.new_wallet,
                            }
                        }
                    }
                })
                const resultPayment = await axios.get(urlPayment)
                if (resultPayment) {
                    this.resultAccountTree = resultPayment.data
                    this.activeWithdraw = await this.$store.dispatch("crypto/checkWithdraw", account)
                }
            } catch(e) {
                console.log("Axios errors InfoBlock:", e)
            }
        },
        closeModal(payload) {
            this.showModal = payload
        },
    },

}
</script>
<style lang="css" scoped>
.break-button {
    margin-left: 10px;
    padding: 5px 20px;
    background: #eacd45;
    color: black;
    border: none;
    border-radius: 10px;
}
p.price {
    font-size: 20px;
}
#infoBlock {
    width: 400px;
    display: block !important;
}
</style>

<style lang="scss" scoped>
*,
::after,
::before {
    box-sizing: border-box;
    outline: none;
}
#balance {
    margin-bottom: 30px;
    margin-top: 24px;
    @media(max-width: 420px) {
        display: block;
    }
}
.balance {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: #1a1a1a;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #828282;
    border-radius: var(--indent-8);
 
    &__title {
        display: flex;
        align-items: center;
        border-radius: var(--indent-8) var(--indent-8) 0 0;
        padding: 24px;
        background: var(--color-white-3);
        color: var(--color-white);
        font-size: 22px;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: -0.01em;
        .tooltip {
            width: 18px;
            height: 18px;
            margin-left: 10px;
            padding: 0;
            border-radius: 50%;
            border: 0;
            background: none;
            cursor: pointer;
        }
    }
    
    &__body {
        padding: 34px 24px;
        @media(max-width: 420px) {
            padding: 16px 24px;
        }
    }
    &__btn {
        font-size: 16px;
        padding: 2px 20px;
        background: var(--color-white-3);
        color: var(--color-black-100);
        border-radius: 10px;
        margin-left: 77px;
        transition: background 600ms, color 600ms;
        cursor: auto;
        @media(max-width: 400px) {
            margin-left: 24px;
        }
        &--active {
            color: #000;
            background: #38c948;
        }
    }
    &__sum {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 24px;
        &-amount {
            display: flex;
            align-items: center;
            font-weight: 600;
            font-size: 22px;
            line-height: 33px;
            color: #ffffff;
            margin-right: 10px;
            margin-right: 10px;
            svg {
                margin-right: 8px;
            }
            &-num {
                margin: 0 8px;
            }
        }
    }
    &__address {
        display: flex;
        flex-direction: column;
        span {
            margin-top: 12px;
            font-size: 16px;
            line-height: 18px;
        }
        &.changed {
            color: #fedd00;
            span {
                margin: 10px 0 6px;
                font-size: 16px;
                line-height: 19px;
            }
        }
    }
    &__tooltip {
        cursor: pointer;
        width: 18px;
        height: 18px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--color-silver);
    }
    &__call-modal {
        display: flex;
        margin-top: 34px;
        text-decoration: none;
        color: #ffffff;
        cursor: pointer;
        @media(max-width: 460px) {
            font-size: 14px;
            margin-top: 22px;
        }
        span {
            text-decoration: underline;
            margin-right: 8px;
        }
        svg {
            margin-left: 10px;
        }
    }
    &__success {
        display: flex;
        align-items: center;
        color: #38c948;
        line-height: 24px;
        margin-top: 16px;
        @media(max-width: 460px) {
            margin-top: 5px;
        }
        svg {
            margin-right: 8px;
        }
    }
}
.btn {
    height: 33px;
    padding: 0 30px;
    font-size: 17px;
    line-height: 33px;
    border: 0;
    border-radius: 10px;
    cursor: pointer;
    &-disabled {
        color: rgba(255, 255, 255, 0.4);
        background: rgba(255, 255, 255, 0.17);
        pointer-events: none;
        cursor: default;
    }
    &-active {
        color: rgba(0, 0, 0, 0.8);
        background: var(--color-emerald);
        pointer-events: auto;
    }
    &-default {
        width: 144px;
        height: 35px;
        font-size: 16px;
        line-height: 24px;
        background: #fedd00;
    }
}
.modal {
    width: calc(100% - 30px);
    max-width: 566px;
    margin: 0 auto;
    padding: 48px 0 40px;
    position: fixed;
    left: 0;
    right: 0;
    border-radius: 8px;
    background: var(--color-black-800);
    color: #ffffff;
    box-shadow: 1px 1px 30px 0px rgba(0, 0, 0, 0.75);
    .close {
        position: absolute;
        top: 25px;
        right: 54px;
        background: transparent;
        border: 0;
        cursor: pointer;
    }
    .modal__body {
        padding: 0 74px 0 94px;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
    }
    &-sm {
        top: 30%;
        height: 230px;
        padding: 72px 0 40px;
        text-align: center;
        .btn {
            color: #000;
            margin-top: 40px;
        }
    }
}
@media screen and (max-width: 460px) {
    .balance {
        height: 280px;
        &__sum {
            flex-direction: column;
            align-items: flex-start;
            grid-row-gap: 13px;
        }
        &__address {
            text-overflow: ellipsis;
        }
    }
    .modal {
        .modal-sm {
            top: 25%;
            height: 240px;
            padding: 62px 0 0;
        }
        .modal__body {
            padding: 0;
        }
        .close {
            top: 15px;
            right: 15px;
        }
    }
}
</style>