<template>

    <section class="settings">
        <!-- <h2 class="settings__title section-title">{{ $t("menu.settings") }}</h2> -->
        <div class="settings-wrap">
            <form class="settings__inner settings-form">
                <!-- <h3 class="settings__account settings__subtitle">{{ $t("settings.accountSettings") }}</h3> -->
                <div class="settings-bio">
                    <!-- <h4 class="settings-bio__title">{{ $t("settings.pic") }}</h4> -->
                    <div class="settings-bio__avatar">
                        <img :src="avatarPath" alt="default avatar" />
                    </div>
                    <div class="settings-upload">
                        <div class="settings-upload__nickname"></div>
                        <div v-if="user?.name" class="settings-upload__force-id">Force ID: {{ user?.name }}</div>
                        <input type="file" id="upload" hidden @change="changeAvatar($event)" />
                    </div>
                </div>
                <div class="settings-bio__buttons-wrap">
                   
                    <!-- <button
                        class="settings-upload__delete btn-reset"
                        type="button"
                        @click="removeAvatar"
                        >
                        <svg-vue icon="trashbox" />
                        {{ $t("settings.deletePic") }}
                    </button> -->
                    <label class="settings-bio__label" for="upload">
                        Update Image
                        <!-- <UiLoader v-if="loading" :loading="true" theme="btn" color="#000" />
                        <span v-else>{{ $t("settings.choosePic") }}</span> -->
                    </label>
                     <ButtonDark 
                        text="Delete Image"
                        class="settings-bio__btn" 
                        @event="removeAvatar" 
                        v-if="hasAvatar"
                    />
                </div>
                <!-- <h3 class="settings-transfer__title settings__subtitle">{{ $t("settings.transferAccount") }}</h3>
                <div class="settings-transfer">
                    <h4 class="settings-transfer__info settings-bio__title">
                        {{ $t("settings.transferInfo") }}
                        <span :content="$t(`settings.transferTooltip`)" v-tippy>
                            <svg-vue icon="info" />
                        </span>
                    </h4>
                    <div class="settings-transfer-inner">
                        <input
                            type="text"
                            class="settings-transfer__input input-reset"
                            v-model="newAddress"
                            :placeholder="$t(`settings.walletAddress`)"
                        />
                        <button
                            class="settings-transfer__send btn-reset glob-btn"
                            type="button"
                            @click="handleTransferClick"
                        >
                            {{ $t("settings.transferButton") }}
                        </button>
                    </div>
                </div> -->
            </form>
                <div class="settings__content">
                    <div class="my-link">
                        <div class="my-link__header">My link</div>
                        <div class="my-link__body">
                            <a class="my-link__link">Link</a>
                            <div class="my-link__wrap">
                                <input 
                                    type="text" 
                                    class="my-link__input" 
                                    :placeholder="refLink"
                                    disabled
                                >
                                <ButtonDark 
                                    classes="my-link__btn"
                                    text="Copy" 
                                    @event="handleClipboardCopy"
                                />
                            </div>
                        </div>
                    </div>
                    <InfoBlock />
                </div>
        </div>
    </section>
</template>

<script>
import pageReadyByConnect from "@/mixin/pageReadyByConnect"
import { $api } from "@/plugins/Axios"
import { mapActions, mapGetters } from "vuex"
import { createImageHref } from "@/helpers/images"
import { isHashAddress } from "@/helpers/user"
import InfoBlock from "@/components/Dashboard/InfoBlock.vue"
import ButtonDark from "@/components/UI/ButtonDark.vue"
import BaseIcon from "@/components/UI/BaseIcon.vue"
import { copyToClipboard } from '@/helpers/clipboard'

export default {
    name: "Settings",

    components: {
        InfoBlock,
        ButtonDark,
        BaseIcon,
    },

    data: () => ({
        avatar: null,
        newAddress: "",
        user: null,
        loading: false,
    }),
    async mounted() {
        if (this.isConnected) {
            await this.pageReady()
        }
    },
    mixins: [pageReadyByConnect],
    methods: {
        ...mapActions("user", ["getUserByField"]),
        async updateUser() {
            this.loading = true
            await this.getUserByField({ account: this.getAccount })
                .then(({ users }) => {
                    this.loading = false
                    this.user = users
                })
                .catch((err) => {
                    this.loading = false
                    this.$swal(err.message)
                })
        },
        async pageReady() {
            await this.updateUser()
        },
        async hasRights() {
            let isLegit = true
            await this.initAuth().catch((err) => {
                isLegit = false
            })

            return isLegit
        },
        async changeAvatar(event) {
            let hasAuth = await this.hasRights()
            if (!hasAuth) return

            this.loading = true
            this.avatar = event.target.files[0]
            let formData = new FormData()
            formData.append("file", this.avatar)
            formData.append("user_id", this.user.id)
            $api.post("users/methods/setavatar", formData)
                .then(() => {
                    this.updateUser()
                })
                .catch((err) => {
                    this.loading = false
                    this.$swal(err.message)
                })
        },
        async removeAvatar() {
            let hasAuth = await this.hasRights()
            if (!hasAuth) return

            this.loading = true
            $api.delete(`users/methods/remove-avatar/${this.user.id}`)
                .then(() => {
                    this.updateUser()
                })
                .catch((err) => {
                    this.loading = false
                    this.$swal(err.message)
                })
        },
        async handleTransferClick() {
            if (!this.newAddress.trim()) return

            if (isHashAddress(this.newAddress)) {
                const modalRes = await this.$swal
                    .fire({
                        showCancelButton: true,
                        confirmButtonText: this.$t("confirm"),
                        cancelButtonText: this.$t("cancel"),
                        text:
                            this.$t("settings.transferAccountConfirmation.partOne") +
                            " " +
                            this.newAddress +
                            "? " +
                            this.$t("settings.transferAccountConfirmation.partTwo"),
                    })
                    .then((result) => {
                        return result.isConfirmed
                    })

                if (modalRes) {
                    await this.startTransfer()
                }
            } else {
                this.$swal(this.$t("settings.transferAccountConfirmation.wrongFormat"))
            }
        },
        async startTransfer() {
            await this.transferAccount({ to: this.newAddress })
        },
        ...mapActions("crypto", ["transferAccount", "initAuth"]),

        handleClipboardCopy() {
        if (!this.refLink) return
        // navigator.clipboard.writeText(text)
        copyToClipboard(this.refLink).then(() => {
                this.$toast.success(`${this.refLink} ${this.$t('account.copied')}`)
            })
            .catch(() => {
                this.$toast.error(`${this.$t('account.copyError')}`)
            })
        },
    },
    computed: {
        ...mapGetters("crypto", ["getAccount", "isConnected", "getBalance"]),
        hasAvatar() {
            return this.user?.avatar
        },
        avatarPath() {
            return createImageHref(this.user?.avatar)
        },
        balanceDai() {
            return this.getBalance.busd
        },
        refLink() {
            if(this.user) {
                const code = this.user?.name
                return `https://${window.location.host}/r/${code}`
            }
        },
    },
}

</script>
